















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import ScopeGroupBuilderScopeGroupItem
  from "@/apps/maintenance-api/pages/Platforms/single/components/scope-group-builder/components/scope-group.vue";

@Component({
  components: {ScopeGroupBuilderScopeGroupItem}
})
export default class PlatformScopeGroupBuilder extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  editingItem?: MaintenanceAPI.Platforms.Item;

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  isEditing: boolean;
}
