var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"form"},[_c('b-card',[(!_vm.editing)?_c('b-card-title',[_vm._v(" "+_vm._s(_vm.editingItem.title)+" "),_c('small',[_vm._v("("+_vm._s(_vm.editingItem.key)+")")]),(_vm.platformEditing && !_vm.editing)?_c('b-button',{staticClass:"ml-1",attrs:{"variant":"flat-primary","size":"sm"},on:{"click":function($event){_vm.editing = true}}},[_vm._v("Edit")]):_vm._e()],1):_vm._e(),(!_vm.editing)?_c('b-card-text',[_vm._v(" "+_vm._s(_vm.editingItem.description)+" ")]):_vm._e(),(_vm.editing)?_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Title","state":errors.length > 0 ? false:null},model:{value:(_vm.item.title),callback:function ($$v) {_vm.$set(_vm.item, "title", $$v)},expression:"item.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1562412520)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Key"}},[_c('validation-provider',{attrs:{"name":"Key","rules":"required"},on:{"blur":function($event){return _vm.$emit('setGroupKey', {key: _vm.i, value: _vm.editingItem.key})}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Key","state":errors.length > 0 ? false:null},model:{value:(_vm.item.key),callback:function ($$v) {_vm.$set(_vm.item, "key", $$v)},expression:"item.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3774166216)})],1)],1)],1):_vm._e(),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"2"}},[_vm._v("Key")]),_c('b-col',{attrs:{"cols":"4"}},[_vm._v("Title")]),_c('b-col',{attrs:{"cols":"4"}},[_vm._v("Description")])],1),_vm._l((_vm.editingItem.scopes),function(scope,key){return _c('b-row',{key:key},[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Key","readonly":!_vm.editing,"state":errors.length > 0 ? false:null},on:{"blur":function($event){return _vm.keyChange(key)}},model:{value:(scope.key),callback:function ($$v) {_vm.$set(scope, "key", $$v)},expression:"scope.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Title","readonly":!_vm.editing,"state":errors.length > 0 ? false:null},model:{value:(scope.title),callback:function ($$v) {_vm.$set(scope, "title", $$v)},expression:"scope.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Description","readonly":!_vm.editing,"state":errors.length > 0 ? false:null},model:{value:(scope.description),callback:function ($$v) {_vm.$set(scope, "description", $$v)},expression:"scope.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.editing)?_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(key)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1),(_vm.editingItem.scopes[Object.keys(_vm.editingItem.scopes)[Object.keys(_vm.editingItem.scopes).length - 1]].key === key)?_c('b-button',{staticClass:"btn-icon ml-2",attrs:{"variant":"outline-info"},on:{"click":_vm.newItem}},[_c('feather-icon',{attrs:{"icon":"FilePlusIcon"}})],1):_vm._e()],1):_vm._e()],1)],1)],1)}),(_vm.editing)?_c('b-row',[(_vm.editing)?_c('b-col',{staticClass:"ml-auto",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"info"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1):_vm._e()],1):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }