



















































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {VForm} from "@/global";
import InfoCard from "./components/info-card.vue";
import PlatformScopeGroupBuilder
  from "./components/scope-group-builder/index.vue";
import {isEqual} from "lodash";
import {
  BButton,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BModal,
  BSpinner,
  BTab,
  BTabs,
  VBModal
} from "bootstrap-vue";
import ScopeSelector from "@/apps/maintenance-api/pages/components/ScopeSelector/index.vue";

@Component({
  components: {
    InfoCard,
    PlatformScopeGroupBuilder,
    BCard,
    BButton,
    ScopeSelector,
    BTabs,
    BTab,
    BModal,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
  }
})
export default class Platform extends Vue {
  $refs!: {
    form: VForm,
    createForm: VForm
  }

  async test() {
    return this.DeepSet(this.item, "scopeGroups.accesslist.description", "test")
  }

  item: MaintenanceAPI.Platforms.Item = null;

  @Watch("id", {immediate: true})
  async watchId() {
    if(this.$route.params.edit != undefined)
      this.editing = true;

    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  toggleEditing() {
    this.editing = !this.editing;
  }

  editing = false;

  async getItem() {
    const item = await this.$api.maintenance.platforms.Get(this.id);
    this.$set(this, "item", item);
  }
  async saveChanges() {
    if (!await this.$refs.form.validate()) return;

    if (this.editing)
      return await this.update();
  }

  async update() {
    const res = await this.$api.maintenance.platforms.Update(this.item.id, {...this.editingItem});
    this.editing = false;
    this.$set(this, "item", res);
  }

  // Editing Functions
  get hasBeenEdited() {
    return !isEqual(this.item, this.editingItem)
  }

  editingItem?: MaintenanceAPI.Platforms.Item = null;

  @Watch('item', {immediate: true, deep: true})
  watchItem() {
    this.$set(this, "editingItem", JSON.parse(JSON.stringify({...this.item})));
  }

  addGroup(key = "", title = "") {

    this.$set(this.editingItem.scopeGroups, "", {
      newItem: true,
      key: key,
      title: title,
      path: this.item.id,
      description: "",
      scopes: {},
      scopeGroups: {}
    })
  }

  saveScopeGroup({key, item}: {key: string, item: MaintenanceAPI.Platforms.ScopeGroupItem}){
    this.$set(this.editingItem.scopeGroups, key, item);
  }

  setGroupKey({key, value}: {key:string, value: string}){
    const item = this.editingItem.scopeGroups[key];
    this.$delete(this.editingItem.scopeGroups, key);
    this.$set(this.editingItem.scopeGroups, value, item)
  }

  setScope({path, value, oldKey}) {
    path = path.replace(this.item.id + ":", "");
    let splitPath = path.split(":");
    let scope = splitPath[splitPath.length - 1];
    splitPath.splice(splitPath.length - 1, 1);

    let newPath = [];
    for (const scopeElement of splitPath) {
      newPath.push("scopeGroups." + scopeElement);
    }


    if(oldKey != value.key) {
      let removeItem = newPath.join(".");
      removeItem += `.scopes.${oldKey}`

      this.DeepSet(this.editingItem, removeItem, undefined);

    }
    newPath.push(`scopes.${scope}`);

    console.log(newPath.join("."));

    this.DeepSet(this.editingItem, newPath.join("."), value);
  }




  // Create Model
  createModel = {
    loading: false,
    key: null,
    title: null,
  }

  async create(bvModalEvt) {
    this.createModel.loading = true;
    bvModalEvt.preventDefault();

    if(!await this.$refs.createForm.validate()) {
      this.createModel.loading = false;
      return;
    }

    this.addGroup(this.createModel.key, this.createModel.title);

    this.createModel.loading = false;

    this.$nextTick(() => {
      this.$bvModal.hide('create-scopegroup-model')
    })
  }

  cancel() {
    this.$refs.createForm.reset();
    this.$set(this, "createModel", {name: null})
  }
}
