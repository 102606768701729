












































































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea, BButton
} from "bootstrap-vue";
import {VForm} from "@/global";

@Component({
  components: {BRow, BCol, BCard, BCardTitle, BCardBody, BCardText, BFormGroup, BFormInput, BFormTextarea, BButton}
})
export default class ScopeGroupBuilderScopeGroupItem extends Vue {
  $refs!: {
    form: VForm
  }

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  platformEditing?: boolean;

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  i?: string;

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: MaintenanceAPI.Platforms.ScopeGroupItem;

  editing = false;

  editingItem?: MaintenanceAPI.Platforms.ScopeGroupItem = null;
  @Watch('item', {immediate: true, deep: true})
  watchItem() {
    this.$set(this, "editingItem", JSON.parse(JSON.stringify({...this.item})));
    delete this.editingItem.newItem;

    if(this.item.newItem){
      this.editing = true;
      this.newItem();
    }
  }

  keyChange(key: string) {
    const item = this.editingItem.scopes[key];
    this.$delete(this.editingItem.scopes, key);
    this.$set(this.editingItem.scopes, item.key, item)
  }

  newItem() {
    this.$set(this.editingItem.scopes, "", {
      key: "",
      title: "",
      description: ""
    })
  }

  removeItem(key: string) {
    this.$delete(this.editingItem.scopes, key);
  }

  save() {
    this.$emit("save", this.editingItem)
    this.editing = false;
  }

  cancel() {
    this.$set(this, "editingItem", JSON.parse(JSON.stringify({...this.item})));
    this.editing = false;
  }
}
